import {useState} from 'react'
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import InstantAccessForm from './InstantAccessForm'

const Contact = () => {
    const [isLoading, setLoading] = useState(false);
const sendEmail = (values) =>{
    setLoading(true);
    var templateParams = {
        from_name: values.name,
        email: values.email,
        message: values.message
    };

    emailjs.send('service_13hszkl','template_se2b4fa', templateParams, 'user_3oXDDFojCHBcX0Rpwkf2Y')
        .then(function(response) {
          if(response.status === 200){
                toast.success('Enquiry submitted successfully !', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    setTimeout(function(){
                        window.location.reload();
                     }, 3000);

          }
        }, function(err) {
           console.log('FAILED...', err);
        })
}


    return (
        <>

            <PageBanner pagename="Conatct Us" />

            {/* section */}
            <Sections classes="WhyMe white-text whyMe watermark contact">
                <div className="flex aic">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/contact/myPick19.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl">
                        <h2>Let’s Connect! I’d love to hear from you.</h2>
                        <p><a className="dbg" href="mailto:hello@shubhamagarwal.me">hello@shubhamagarwal.me</a></p>
                        <p>Or</p>
                        <Formik
                            initialValues={{ name: '', email: '', message: '' }}
                            validate={values => {
                                const errors = {};
                                if (!values.name) {
                                    errors.name = 'Name is required';
                                }
                                if (!values.email) {
                                    errors.email = 'Email is required';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                }
                                if (!values.message) {
                                    errors.message = 'Name is message';
                                }
                                return errors;
                            }}
                            onSubmit={sendEmail}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex aic drkform">
                                        <div className="label"><label htmlFor="name">Name</label></div>
                                        <div className="flexGrow-1 input">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Enter Your Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            
                                        </div>
                                       
                                    </div>
                                  <div className='help-error'> {errors.name && touched.name && errors.name}</div>
                                    <div className="flex aic drkform">
                                        <div className="label"><label htmlFor="email">Email</label></div>
                                        <div className="flexGrow-1 input">
                                            <input type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Enter Your Email Id "
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                          
                                        </div>

                                    </div>
                                    <div className='help-error'>{errors.email && touched.email && errors.email}</div>
                                    <div className="flex drkform">
                                        <div className="label"><label htmlFor="message">Message</label></div>
                                        <div className="flexGrow-1 input">
                                            <textarea id="message" name="message"
                                                placeholder="Enter Your Message" rows={4} cols={50}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.message}
                                            />
                                            
                                        </div>
                                    </div>
                                    <div className='help-error'>{errors.message && touched.message && errors.message}</div>

                                   
                                <button className="link" disabled={isSubmitting}>{isLoading ? 'Please wait...' : 'Submit'}</button>
                                </form>
                            )}
                        </Formik>




                    </div>
                </div>
            </Sections>
            {/* section */}
            <Sections classes="conLight WhyMe">
                <div className="flex aic">
                    <div className="contactimg"><img src={process.env.PUBLIC_URL + "./assets/img/contact/myPick20.jpg"} alt="shubham agarwal" /></div>
                    <div className="flexGrow-1 pdl pdr">
                        <h2 className="text-center">
                             REQUEST FOR FREE LIFE ASSESSMENT
                            <p className="text-center"><i> …And get crystal clear on your values, priorities, purpose & vision!</i></p>
                        </h2>

                    </div>
                    <div className="contactimg"><img src={process.env.PUBLIC_URL + "./assets/img/you/youare.jpg"} alt="shubham agarwal" /></div>
                </div>
                <p>My free tool guide is meant to help you take a bird’s-eye view of all the areas of your life. With this, not only you will become aware of the time & energy needed to be spent on those respective areas, but also form a strategic approach for getting closer to your desired outcome at your own pace and in a consistent progressive fashion.</p>
                <p>Just enter your name and email to get instant access!</p>
                <InstantAccessForm/>

            </Sections>
            <Sections classes="conLight conGreenish WhyMe">
                <div className="text-center">
                    <a href="https://calendly.com/coachingwithshubham/complimentary-discovery-coaching-call" className="link">BOOK YOUR COMPLIMENTARY DISCOVERY CALL</a>
                    <p className="text-center"> <b>OR</b></p>
                    <a href="/1:1-Coaching-Program" className="link">VIEW MY COACHING PROGRAM
                    </a>
                </div>
            </Sections>
        </>
    )
}

export default Contact
