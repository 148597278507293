
const BannerOne = () => {
    return (
        <div className="banner BannerOne">
        <img src="assets/img/home-page/1.jpg" alt="car" className="bannerimg"/>
<div className="bannercon">
<div className="container text-left">
<div className="row">
    <div className="col-9 col-md-10 col-sm-12">
    <h1>SHUBHAM AGARWAL</h1> 
   <h2> BUSINESS & LIFE COACH</h2>
   

   <p><b>CONNECT</b> WITH YOUR SOUL PRINT </p>
   <p><b>DISCOVER</b> YOUR PASSION & PURPOSE </p>
   <p><b>TRANSCEND</b> YOUR FEARS & LIMITING BELIEFS</p>
   <p><b>RISE</b> IN SELF-LOVE</p>
   <p><b>ELEVATE</b> YOUR CONSCIOUSNESS</p>
   <p><b>UNLOCK</b> YOUR TRUE POTENTIAL</p>
   <p><b>THRIVE</b> TO MEET THE HERO WITHIN </p>
   <p><b>PROPEL</b> TO GREATNESS</p>
   <p><b>FULFIL</b> THE DESTINY OF YOUR UNTETHERED SOUL</p>
   <a href="https://calendly.com/coachingwithshubham/complimentary-discovery-coaching-call" className="link">BOOK YOUR FREE DISCOVERY COACHING CALL</a>
    </div>
</div>
</div>
</div>
            </div>
    )
}

export default BannerOne
