

import PageBanner from "../../components/PageBanner"
import Sections from "../../components/Sections"
const CoachingDisclaimer= () => {
    return (
        <>
            <PageBanner pagename="COACHING DISCLAIMER" />

            {/* section */}
            <Sections classes="WhyMe conGreenish">

            <h2>DESCRIPTION OF COACHING</h2>
            <p>Coaching is partnership (defined as an alliance, not a legal business partnership) between the Coach and the Client in a thought-provoking and creative process that inspires the client to maximize personal and professional potential. It is designed to facilitate the creation/ development of personal, professional or business goals and to develop and carry out a strategy/plan for achieving those goals.</p>

            <h2>COACH-CLIENT RELATIONSHIP</h2>
            
            <ol className="list reab ablist">
           <li> Coach agrees to maintain the ethics and standards of behaviour established by the Association for Coaching “(AC)” global codes of ethics. It is recommended that the Client review the AC Code of Ethics and the applicable standards of behaviour </li>

           <li>Client is solely responsible for creating and implementing his/her own physical, mental and emotional well-being, decisions, choices, actions and results arising out of or resulting from the coaching relationship and his/her coaching calls and interactions with the Coach. As such, the Client agrees that the Coach is not and will not be liable or responsible for any actions or inaction, or for any direct or indirect result of any services provided by the Coach. Client understands coaching is not therapy and does not substitute for therapy if needed, and does not prevent, cure, or treat any mental disorder or medical disease. </li>

           <li>Client further acknowledges that he/she may terminate or discontinue the coaching relationship at any time by notifying the Coach in writing. </li>

           <li>Client acknowledges that coaching is a comprehensive process that may involve different areas of his or her life, including work, finances, health, relationships, education and recreation. The Client agrees that deciding how to handle these issues, incorporate coaching principles into those areas and implementing choices is exclusively the Client’s responsibility.  </li>

           <li>Client acknowledges that coaching does not involve the diagnosis or treatment of mental disorders as defined by the American Psychiatric Association and that coaching is not to be used as a substitute for counselling, psychotherapy, psychoanalysis, mental health care, substance abuse treatment, or other professional advice by legal, medical or other qualified professionals and that it is the Client’s exclusive responsibility to seek such independent professional guidance as needed. If Client is currently under the care of a mental health professional, it is recommended that the Client promptly inform the mental health care provider of the nature and extent of the coaching relationship agreed upon by the Client and the Coach. </li>

           <li>The Client understands that in order to enhance the coaching relationship, the Client agrees to communicate honestly, be open to feedback and assistance and to create the time and energy to participate fully in the program. </li>
            </ol>

            </Sections>
            {/* section */}
          
            </>
    )
}
export default CoachingDisclaimer
