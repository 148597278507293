

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="flex aic">
                    <div className="pdr">
                        <div className="text-center">  <a href="https://www.facebook.com/shubhamagarwalcoaching" rel="noreferrer" target='_blank' className="social"><i className="i-facebook"></i></a>
                        <a href="https://www.instagram.com/shubhamquestlife/" rel="noreferrer" target='_blank' className="social"><i className="i-instagram"></i></a>
                        <a href="https://www.linkedin.com/in/shubhamquestlife" rel="noreferrer" target='_blank' className="social"><i className="i-linkedin"></i></a></div>
                      
                        <p>Copyright Shubham Agarwal © 2021  </p>
                    </div>
                    <div className="flexGrow-1 text-right"><img src={process.env.PUBLIC_URL + "/assets/img/home-page/jay.jpg"} alt="shubham agarwal" /></div>
                    <div><img src={process.env.PUBLIC_URL + "/assets/img/home-page/footerlogos.jpg"} alt="shubham agarwal" /></div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
