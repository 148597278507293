import Sections from "../Sections"

const Socrates = () => {
    return (
        <Sections classes="qoute socrates ">
                <div className="quotes">Everyone tells you what to do and what's good for you. They don't want 
you to find your own answers. They want you to believe theirs. </div>
<p className="text-center"><b>– Socrates</b></p>
    </Sections>
    )
}

export default Socrates
