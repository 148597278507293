import Sections from "../Sections"
const Ways = () => {
    return (
        <Sections classes="conGreenish">
            <div className="flex aic ways">
                <div className="flexGrow-1 pdr">
                    <h2>There are two ways to live your life:</h2>
                    <p>1.   Live unconsciously and let someone else define your goal & design your path. </p>
                    <p>OR</p>
                    <p>2. Live consciously & design your own path to get to your self-directed meaningful goals. </p>
                    <p>Neither is easy but with conscious living, you will find Fulfilment on the other side of pain. </p>
                    <p>Now, how do you want to live the rest of your life, it’s yours to decide!</p>
                </div>
                <div>
                    <img src={process.env.PUBLIC_URL + "./assets/img/home-page/path.jpg"} alt="shubham agarwal" />
                </div>
            </div>

        </Sections>
    )
}

export default Ways
