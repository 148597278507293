import { useState } from "react";
import {Link, NavLink} from "react-router-dom";
import {Services}  from './submenu/Submenu';
function Header(){
const [header, setHeader] = useState(false);
const [toggle, settoggle] = useState(false);
const toggler = () =>{
    toggle ? settoggle(false) : settoggle(true);
}
const changebg = () =>{
   if(window.scrollY > 40){
       setHeader(true);
   }
   else{
       setHeader(false);
   }
}
window.addEventListener('scroll', changebg);
    return (
        <>
             <header className={header ? 'header active' : 'header' }>
   <div className="container nbar  csb">
<div className="brand"><Link to="/"><img src={process.env.PUBLIC_URL + "/assets/img/sa-logo.svg"} alt="GEC logo"/></Link></div>
<div className="navebar">
<button onClick={toggler} className={toggle ? "menubutton opened" : "menubutton"}>
<span></span><span></span><span></span>
</button> 
<ul className={toggle ? "nav opened" : "nav"}>
<li><NavLink onClick={toggler} to="/about-us" exact activeClassName="activelink">About</NavLink></li>
<li><NavLink onClick={toggler} to="/you" exact activeClassName="activelink">You</NavLink></li>
<li><NavLink onClick={toggler} to="/me" exact activeClassName="activelink">Me</NavLink></li>
<li><span className="submenu">Services<i className="i-angle-down"/>   
<Services click={toggler}/>
</span>
</li>
<li><NavLink onClick={toggler} to="/1:1-Coaching-Program" exact activeClassName="activelink"> 1:1 COACHING PROGRAM</NavLink></li>
<li><NavLink onClick={toggler} to="/learn" exact activeClassName="activelink">Learn</NavLink></li>
<li><NavLink onClick={toggler} to="/testimonials" exact activeClassName="activelink">Testimonials</NavLink></li>
<li><NavLink onClick={toggler} to="/contact" exact activeClassName="activelink">Contact</NavLink></li>
</ul>
</div>
 </div>
</header> 
        </>
 
    )
}
export default Header
