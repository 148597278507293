import React, {useState} from 'react'
import { Formik } from 'formik';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
const InstantAccessForm = () => {
    const [isLoading, setLoading] = useState(false);
    const sendEmail = (values) =>{
        setLoading(true);
        var templateParams = {
            from_name: values.name,
            email: values.email,
        };
    
        emailjs.send('service_13hszkl','template_rtz5von', templateParams, 'user_3oXDDFojCHBcX0Rpwkf2Y')
            .then(function(response) {
              if(response.status === 200){
                    toast.success('Enquiry submitted successfully !', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                        setTimeout(function(){
                            window.location.reload();
                         }, 3000);
    
              }
            }, function(err) {
               console.log('FAILED...', err);
            })
    }
    return (
        <>

            <Formik
                initialValues={{ name: '', email: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.name) {
                        errors.name = 'Name is required';
                    }
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                   
                    return errors;
                }}
                onSubmit={sendEmail}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="flex aic drkform lgtform">
                            <div className="label"><label htmlFor="name">Name</label></div>
                            <div className="flexGrow-1 input">
                                <input type="text" 
                                className='atf'
                                id="name"
                                 name="name" 
                                 placeholder="Enter Your Name" 
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 value={values.name}
                                 />
                            </div>
                            
                        </div>
                        <div className='help-error2'>{errors.name && touched.name && errors.name}</div>
                        <div className="flex aic drkform lgtform">
                            <div className="label"><label htmlFor="email">Email</label></div>
                            <div className="flexGrow-1 input">
                                <input type="email" 
                                 className='atf'
                                id="email" 
                                name="email" 
                                placeholder="Enter Your Email Id " 
                                onChange={handleChange}
                                 onBlur={handleBlur}
                                 value={values.email}
                                />
                            </div>
                            
                        </div>
                        <div className='help-error2'> {errors.email && touched.email && errors.email}</div>


                        <div className="text-center"> 
                        <button className="link" disabled={isSubmitting}>{isLoading ? 'Please wait...' : 'GET INSTANT ACCESS NOW'}</button>
                        </div>
                    </form>
                )}
            </Formik>

        </>
    )
}

export default InstantAccessForm
