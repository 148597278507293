import Sections from "../Sections"

const MypickThree = () => {
    return (
        <Sections classes="qoute mypick3">
        <div className="flex aic">
            <div>
            <img src={process.env.PUBLIC_URL + "./assets/img/home-page/myPick3.jpg"} alt="shubham agarwal"/>
            </div>
            <div className="flexGrow-1 pdl">
                <div className="quotes">The reward of experiencing a life of meaning, purpose & fulfilment comes with taking 100% responsibility for your life.<br/><br/>
The Meaning of life is to recognize and nurture our innate gift. The Purpose of life is to share it with the world for a better tomorrow. Meaning is for self. Purpose is for others.</div>
            </div>
        </div>
    </Sections>
    )
}

export default MypickThree
