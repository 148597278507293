
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"

const AboutUs = () => {
    return (
        <>
            <PageBanner pagename="About Us" />
            <Sections classes="qoute collaboration conGreenish welcome">
                <div className="flex aic">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/about/myPick14.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl">
                        <h2>WELCOME TO SHUBHAM AGARWAL COACHING</h2>

                        <p className="tags"><span>Break Through Limiting Beliefs</span>
                            <span>Adopt Empowering Soulset</span><br />
                            <span>Discover True Potential</span>
                            <span>Experience Lasting Fulfilment</span>
                        </p>

                    </div>
                </div>
            </Sections>

            {/* section */}

            <Sections classes="qoute meShubham">

                <div className="quotes">Everyone tells you what to do and what's good for you. They don't want you to find your own answers. They want you to believe theirs.</div><p className="text-center"><b> - Socrates</b></p>


            </Sections>
            {/* section */}
            <Sections classes="conLight conGreenish WhyMe">

                <div className="flex aic">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/about/coachingLife.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl">
                        <p>Discover Your Answers</p>
                        <p>Find Your Purpose</p>
                        <p>Be Your Authentic Self</p>
                        <p>Craft A Fulfilling Life</p>
                    </div>
                </div>
            </Sections>
            {/* section */}
            <Sections classes="white-text watermark grow">
                <h2>TOGETHER, LET’S SOW THE SEED OF YOUR PURPOSE  <br /> SO YOU CAN REAP THE FRUIT OF FULFILMENT.</h2>
            </Sections>

            <Sections classes="conLight WhyMe">


                <div className="flex aic">
                    <div className="flexGrow-1 pdr"><p>My coaching program is established on an equal-footing collaborative effort that is focused on aiding you to find your answers and thus covers the following key reflective elements : </p>
                        <ul className="list ol">
                            <li>Assess your present situation and pain points.</li>
                            <li>Guide you to identify & align your values, personality, passion, skills, purpose & vision.</li>
                            <li>Encourage you to do inner work towards self-discovery.</li>
                            <li>Support you to discover and set your worthwhile goals.</li>
                            <li>Identify obstacles & roadblocks.</li>
                            <li>Explore the options & resources available.</li>
                            <li>Foster accountability to put in the work towards forming success habits to affect accelerated progress.</li>
                            <li>Nurture and elicit self-generated solutions, strategies & plan of action.</li>
                            <li>Encourage you to push past your comfort zone, choose the best path forward and take responsibility for your actions.</li>
                            <li>Facilitate you with prioritizing your action steps toward getting closer to your meaningful goals.</li>
                            <li>Motivate you to generate the momentum towards navigating forward with timely reflections & adjustments along the way.</li>
                            <li>Celebrate your achievements and wins.</li>
                        </ul>
                    </div>
                    <div className="img50-50">
                        <img src={process.env.PUBLIC_URL + "./assets/img/about/hand-tree.jpg"} alt="shubham agarwal" />
                        <img src={process.env.PUBLIC_URL + "./assets/img/about/purpose.jpg"} alt="shubham agarwal" />
                        <img src={process.env.PUBLIC_URL + "./assets/img/about/start-living.jpg"} alt="shubham agarwal" />
                    </div>
                </div>
            </Sections>
            {/* section */}

            <Sections classes="conDark WhyMe">
                <h2>WHAT CAN YOU EXPECT FROM ME?</h2>
                <div className="row rowimg">
                    <div className="col-6 "><img src={process.env.PUBLIC_URL + "./assets/img/about/mind-body-soul.jpg"} alt="shubham agarwal" />   </div>
                    <div className="col-6 "><img src={process.env.PUBLIC_URL + "./assets/img/about/stepup.jpg"} alt="shubham agarwal" /> </div>
                </div>
                <ul className="list">
                    <li>Be your biggest cheerleader & companion in your authentic journey of growth & fulfilment.</li>
                    <li>Provide a reflective space by listening mindfully & patiently.</li>
                    <li>Be a sounding board for your ideas & opinions.</li>
                    <li>Ask thought-provoking questions to raise insights & broaden perspective.</li>
                    <li>Be fully present with your energy.</li>
                    <li>Keep a close observation and comprehend the unsaid.</li>
                    <li>Take you on an inward journey, sometimes to places you thought never existed.</li>
                    <li>Facilitate the process of self-directed learning and self-driven change.</li>
                    <li>Support you to build relevant strategies towards meeting your outcomes.</li>
                    <li>Guide you to form right habits over the long term.</li>
                    <li>Be your lighthouse to help you stay on course.</li>
                    <li>Support you to progress at your own pace.</li>
                    <li>Help you connect the dots.</li>
                    <li>Provide access to my tools and resources.</li>
                    <li>Maintain ethical standards & professional boundaries.</li>

                    <li>Provide you a safe empowering space free of judgements & biases and filled with confidentiality, trust, authenticity, empathy, commitment, integrity & respect.</li>
                </ul>
                <div className="flex">
                    <div className="flexGrow-1 pdr">
                        <p>
                            Furthermore, I will take you from your present state of being Functional to your new state of becoming Phenomenal through my tailor-made coaching program, using personalised coaching tools, techniques & strategies, respecting your autonomy, being your sounding board and asking open-ended & reflective questions.
                        </p>
                        <p>The add-on value I bring to my program is establishing clear expectations & boundaries at the very beginning, co-creating the coaching agreement and providing a nurturing space so you feel the ease to trust the process, openly share your feelings & take the liberty to be vulnerable to your very core. Most importantly, you will get to experience deep listening and my passion for making a difference in people’s lives. </p>
                    </div>
                    <div> <img src={process.env.PUBLIC_URL + "./assets/img/about/zone.jpg"} alt="shubham agarwal" /> </div>

                </div>

            </Sections>
            {/* section */}
            <Sections classes="conLight WhyMe">
                <div className="flex aic">
                    <div> <img src={process.env.PUBLIC_URL + "./assets/img/about/myPick15.jpg"} alt="shubham agarwal" /> </div>
                    <div className="flexGrow-1 pdl">
                        <p><b>In our coaching partnership, you will accelerate on your remarkable journey of :    </b> </p>
                        <ul className="list">
                            <li>Uncovering Your Blind Spots.</li>
                            <li>Deconstructing Your Pain Points.</li>
                            <li>Debunking Negative Beliefs.</li>
                            <li>Transcending Your Fears.</li>
                            <li>Breaking Through Unserving Patterns.</li>
                            <li>Dismantling Unwarranted Anxieties & Self-Doubts.</li>
                            <li>Unravelling Dilemmas.</li>
                            <li>Broadening Perspective.</li>
                            <li>Developing an Abundance Mindset.</li>
                            <li>Rising in Self-Love & Self-Esteem.</li>
                            <li>Making a Shift from Struggling to Thriving with Heightened Self-Belief & Self-Worth.</li>
                            <li>Rediscovering the Person You Were Always Meant To Be.</li>
                            <li>Living Your Purpose and Fulfilling Your Inherent Limitless Potential.</li>

                        </ul>
                    </div>
                </div>

                <p><b>Through a personalized support system, my clients make a shift from the victim to a creator mindset and gain the clarity & confidence to overcome their pressing challenges, actualise their meaningful goals and live their purpose.
                </b></p>

            </Sections>
            {/* section */}
            <Sections classes="conLight conGreenish WhyMe">
                <h2>WHAT DO I EXPECT OF YOU?</h2>
                <div className="flex aic">
                    <div className="flexGrow-1 pdr">
                        <ul className="list ol">
                            <li>Show up for the session on time.</li>
                            <li>Minimize distractions during the session.</li>
                            <li>Ensure having a good internet connection and audio/video quality (applies to online sessions).</li>
                            <li>Be fully present during the session.</li>
                            <li>Be open & honest.</li>
                            <li>Commit to your goals.</li>
                            <li>Be driven to put in the work.</li>
                            <li>Be ready to push outside your comfort zone.</li>
                            <li>Focus on making progress, however small it may be.</li>
                            <li>Reflect and record insights for each session.</li>
                        </ul>
                    </div>
                    <div className="img50-50">
                        <img src={process.env.PUBLIC_URL + "./assets/img/about/new-life.jpg"} alt="shubham agarwal" />
                        <img src={process.env.PUBLIC_URL + "./assets/img/about/new-way.jpg"} alt="shubham agarwal" />
                    </div>
                </div>
                <p><b>So, are you ready to invest in your growth & success and willing to have hands-on experience of partnering with me? </b></p>
                <p><b>You have curbed your inner voice long enough. Help me help you bring it to life.</b></p>
                <p><b>I welcome you to board my coaching boat.</b></p>
                <div className="text-center">
                    <a href="https://calendly.com/coachingwithshubham/complimentary-discovery-coaching-call" className="link"> BOOK YOUR FREE COACHING SESSION </a>
                </div>
            </Sections>

            {/* section */}
            <Sections classes="white-text watermark focus">
                <h2 className="mainH">MY FOCUS COACHING AREAS</h2>
                <div className="row multiple-sm">
                    <div className="col-6 col-sm-12">
                        <div className="abtcoaching">
                            <a href="/career-coaching"><h2>CAREER COACHING</h2></a>
                            <p>Career Clarity, Career Transition, Career Misalignment, Career Progression, Career Fulfilment, Pay Raise, Unemployment Woes, Interpersonal Skills, Leadership Development, Performance Enhancement, Sense of Belongingness, Confidence Issues, Fears & Limiting Beliefs, Workplace Conflict, Workplace Anxiety, Workplace Engagement, Toxic Work Environment, Work Life Balance, Professional & Business Milestones.   </p>
                            <a href="/career-coaching" className="link">KNOW MORE</a>
                        </div>
                    </div>
                    <div className="col-6 col-sm-12">
                        <div className="abtcoaching">
                            <a href="/life-coaching"><h2>LIFE COACHING</h2></a>
                            <p>Clarity of Purpose, Perspective & Mindset Shift, Health & Wellness, Self-love & Self-Esteem, Self-belief & Self-worth, Inner Liberation, Success Habits, Consciousness, Focus, Uplifting Associations, Meaningful Relationships, Personal Growth.   </p>
                            <a href="/life-coaching" className="link">KNOW MORE</a>
                        </div>
                    </div>
                </div>
                <div className="text-center mtop20">
                    <a href="/1:1-Coaching-Program" className="link">
                        VIEW MY COACHING PROGRAM
                    </a>
                </div>
            </Sections>

            {/* section */}
            <Sections classes="qoute purquote2">
                <div className="quotes">The cave you fear to enter holds the treasure you seek.</div>
                <p className="text-center"><b> – Joseph Campbell</b></p>
            </Sections>
            {/* section */}


            <Sections classes="conLight WhyMe conGreenish">
                <div className="flex aic">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/about/myPick16.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl hline">
                        <h2>Click <a className="textlink" href="/contact">HERE</a> to grab your free life assessment ticket and get crystal clear on your values, priorities, purpose & vision!</h2>
                        <p><i>We often experience life taking unexpected turns, bringing a multitude of challenges & struggles along the way. Although we may not be able to control, predict or prevent them, we do possess the ability to make a conscious choice of how we perceive & respond to these unfavourable situations to our advantage. </i></p>

                        <p><i>Crafting a meaningful life is an inside-out process and not vice-versa, i.e., we must learn to live consciously, intently & purposefully.  </i></p>

                        <p><i>The path to realizing this feat is taking an inward journey of cultivating deeper self-awareness, self-love and self-discipline. When this happens, we experience our greater evolved self, strengthen our roots to face and thrive through any storm life throws at us and become a nurturing force to empower the lives around us, thereby creating a beautiful ripple effect for a better tomorrow. </i></p>


                    </div>
                </div>
                <div className="hasTag">
                    <p><i>#LIFECOACHING #CAREERCOACHING #PURPOSECOACHING #FULFILMENTCOACHING #WELLNESSCOACHING #CONFIDENCECOACHING #MINDSETCOACHING #PERSPECTIVECOACHING #SUCCESSCOACHING #THRIVECOACHING #CONSCIOUSNESSCOACHING #STRESSCOACHING #ANXIETYCOACHING #GUILTCOACHING #GRIEFCOACHING #AGGRESSIONCOACHING #PERFORMANCECOACHING #EMOTIONALCOACHING #BEHAVIORALCOACHING #TRANSFORMATIONALCOACHING #HABITSCOACHING  #LEADERSHIPCOACHING #BUSINESSCOACHING #ENTREPRENEURSHIPCOACHING #EXECUTIVECOACHING #TEAMCOACHING #RELATIONSHIPCOACHING #SPIRITUALCOACHING</i></p>
                </div>

            </Sections>

        </>
    )
}

export default AboutUs
