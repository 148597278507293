
const PageBanner = (props) => {
    return (
        <div className="pagebanner">
          <div className="container">
              <h1>{props.pagename}</h1>
          </div>
        </div>
    )
}
export default PageBanner
