import Banner from "../components/homeComponents/Banner"
import Checkout from "../components/homeComponents/Checkout"
import Coach from "../components/homeComponents/Coach"
import Collaboration from "../components/homeComponents/Collaboration"
import Committed from "../components/homeComponents/Committed"
import MypickFour from "../components/homeComponents/MypickFour"
import MypickSeven from "../components/homeComponents/MypickSeven"
import MypickThree from "../components/homeComponents/MypickThree"
import MypickTwo from "../components/homeComponents/MypickTwo"
import Purpose from "../components/homeComponents/Purpose"
import Ready from "../components/homeComponents/Ready"
import Socrates from "../components/homeComponents/Socrates"
import Values from "../components/homeComponents/Values"
import Ways from "../components/homeComponents/Ways"
import WhyMe from "../components/homeComponents/WhyMe"
import WorkWith from "../components/homeComponents/WorkWith"
const Home = () => {
    return (
      <>
<Banner/>
<MypickTwo/>
<Ready/>
<MypickThree/>
<Ways/>
<MypickFour/>
<Coach/>
<Socrates/>
<Values/>
<Collaboration/>
<WorkWith/>
<Purpose/>
<Committed/>
<MypickSeven/>
<WhyMe/>
<Checkout/>
      </>
    )
}

export default Home
