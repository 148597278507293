import Sections from "../Sections"

const Values = () => {
    return (
        <Sections classes="conDark values">
        <div className="flex aic ">
        <div>
                <img src={process.env.PUBLIC_URL + "./assets/img/home-page/myPick5.jpg"} alt="shubham agarwal" />
            </div>
            <div className="flexGrow-1 pdl">
         <p>I am committed to serving and empowering driven individuals & professionals achieve greatness & fulfilment by providing them a nurturing space to get on their authentic & meaningful journey of discovering their soul print, breaking old unserving patterns, inculcating positive habits and igniting innate potential, so that they can actualize a remarkable form of their uniquely gifted and impactful life. </p>
         <div className="flex aic">
<i className="i-values cicon"/> <p>My signature coaching program is crafted on the foundational pillars of my core values, which are
AUTHENTICITY, INTEGRITY, EMPATHY, TRUST, COMMITMENT and RESPECT.</p>
         </div>
         <p className="progress">Introspection + Passion + Purpose + Consistent Action + Micro-failures + Micro-successes + Balanced Reflection + Pain + Rest + Repeat <i className="i-big-arrrow"/> Progress + Greatness + Fulfilment </p>
            </div>
          
        </div>

    </Sections>
    )
}

export default Values
