
import Sections from "../Sections"

const Checkout = () => {
    return (
        <Sections classes="conGreenish">
<div className="row aic checkout">
<div className="col-6 col-md-6 col-sm-12">
    <img src={process.env.PUBLIC_URL + "./assets/img/home-page/myPick9.jpg"} alt="shubham agarwal"/>
    </div>
    <div className="col-6 col-md-6 col-sm-12">
    <h2>CHECKOUT…</h2>
    <a href="/group-coaching-for-organizations" className="link">TEAM COACHING FOR ORGANIZATIONS</a>
    <a href="/career-coaching" className="link">CAREER COACHING FOR INDIVIDUALS</a>
    <a href="/life-coaching" className="link">LIFE COACHING FOR INDIVIDUALS</a>
    <a href="/1:1-Coaching-Program" className="link">COACHING PROGRAM FOR INDIVIDUALS</a>
    <a href="/testimonials" className="link">RAVE REVIEWS</a>
    <a href="/contact" className="link">YOUR FREE LIFE ASSESSMENT</a>
    <a href="/learn" className="link">LEARN</a>
    </div>

</div>

</Sections>
    )
}

export default Checkout
