import Sections from "../Sections"

const MypickSeven = () => {
    return (
        <Sections classes="qoute mypickSeven">
        <div className="flex aic">
            <div>
            <img src={process.env.PUBLIC_URL + "./assets/img/home-page/myPick7.jpg"} alt="shubham agarwal"/>
            </div>
            <div className="flexGrow-1 pdl">
                <div className="quotes">Greatness is when our passion, purpose, skills, values & vision are consciously aligned and are in harmony with our habits & actions, thereby inspiring others to add greatness to their lives. That’s when we experience being alive. <br/><br/>
                Success does not come from a painless journey. There is no such thing as a resourceful person with an easy past.</div>
            </div>
        </div>
    </Sections>
    )
}

export default MypickSeven
