
import Card from "../components/Card"
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"
const Learn= () => {
    return (
        <>
            <PageBanner pagename="Learn" />
            {/* section */}
     
            {/* section */}
            <Sections classes="conGreenish">
      <div className="row multiple">
                    <Card 
                    graphic={process.env.PUBLIC_URL + "/assets/img/learn/disclaimer.jpg"}
                    title="COACHING DISCLAIMER" 
             
                    goTo="/learn/coaching-disclaimer"
                     />

                     <Card 
                    graphic={process.env.PUBLIC_URL + "/assets/img/learn/association.jpg"}
                    title="GLOBAL CODE OF ETHICS" 
                  
                    goTo="/learn/Global-Code-of-Ethics"
                     />
    
                     <Card 
                    graphic={process.env.PUBLIC_URL + "/assets/img/learn/life-coaching.jpg"}
                    title="LIFE COACHING UNCOVERED" 
                  
                    goTo="/learn/Life-Coaching-Uncovered"
                     />

                        </div>
            </Sections>
            {/* section */}
          
            </>
    )
}
export default Learn
