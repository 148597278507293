import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BannerOne from './BannerOne';
import BannerTwo from './BannerTwo';
import BannerThree from './BannerThree';

const Banner = () => {
    return (
        <Carousel showArrows={true} autoPlay={false}  showStatus={false}> 
        <BannerOne/>
       <BannerTwo 
       bg="assets/img/home-page/2-2.jpg"
       hOne="REDISCOVER THE PERSON YOU WERE ALWAYS MEANT TO BE & FULFIL YOUR TRUE POTENTIAL. "
       hTwo="TAKE CHARGE. REWRITE YOUR STORY. COME ALIVE."
       hThree="IT’S YOUR TIME TO GET UNSTUCK & SHINE"
       linkc="BOOK YOUR FREE DISCOVERY SESSION"
       link="https://calendly.com/coachingwithshubham/complimentary-discovery-coaching-call"
       content="Listen To The Voice Within | Discover Your Soul Purpose | Fulfil Your Highest Potential | Live An Authentically Vibrant Life"
       />

<BannerThree 
       bg="assets/img/home-page/3-3.jpg"
       hOne="TAKE THE LEAP AND SET OUT ON YOUR VERY OWN AUTHENTIC JOURNEY OF SELF-DISCOVERY & FULFILMENT"
       hThree="DISCOVER WHAT YOU ARE CAPABLE OF ACHIEVING & CREATE THE LIFE YOU WERE MEANT TO HAVE"
       linkc="BOOK YOUR COMPLIMENTARY DISCOVERY SESSION"
       link="https://calendly.com/coachingwithshubham/complimentary-discovery-coaching-call"
       content="Follow Your Passion | Live Your Purpose | Rise in Self Love | Be Your Whole Authentic Self"
       />
 <BannerThree 
       bg="assets/img/home-page/4-4.jpg"
       hOne="MOVE FROM STRUGGLING TO THRIVING IN YOUR CAREER, BUSINESS AND LIFE."
       hThree="CRAFT AN AUTHENTIC, VIBRANT, JOYFUL & A FULFILLING LIFE"
       linkc="BOOK YOUR COMPLIMENTARY COACHING SESSION"
       link="https://calendly.com/coachingwithshubham/complimentary-discovery-coaching-call"
       content="Break Through Limiting Beliefs | Adopt Empowering Soulset | Unlock Latent Potential | Experience Lasting Fulfilment"
       />
 
        </Carousel>
    )
}

export default Banner
