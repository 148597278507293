

import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"
import ReviewsThumb from "../components/ReviewsThumb"
const Testimonials= () => {
    return (
        <>
            <PageBanner pagename="Testimonials" />
            {/* section */}

               {/* section */}
     <Sections classes="conLight WhyMe">
     <div className="flex aic">
    <div><img src={process.env.PUBLIC_URL + "/assets/img/myPick24.jpg"} alt="myPick"/></div>
    <div class="flexGrow-1 pdl">
<h2>WHAT MY CLIENTS SAY… </h2>
<p>I have coached some amazing clients from different parts of the world and from varied backgrounds. </p>
<p>Here is what few of them have said about their coaching experience!</p>

    </div>
</div>
     </Sections>
            {/* section */}
            <Sections classes="conGreenish">
      <div className="row multiple">
              
                    <ReviewsThumb
                        reviewerName="Angela Human"
                        locations="North Carolina, USA"
                        icons={process.env.PUBLIC_URL + "/assets/img/testimonials/Angela-Human.jpg"}>
                        I am very grateful for the positive impact that these sessions have had on my outlook & approach in terms of the topics we discussed. They were empowering to say the least!
                        Shubham has the ability to really listen to what is being said & then ask questions in such a way that almost instantaneously makes one see the issue from a different perspective.  Suffice to say that I experienced some “aha” moments in each session.
                        <br />  <br />
                        I wish him continued success. May he continue to share his wisdom with the world!
                    </ReviewsThumb>

                    <ReviewsThumb
                        reviewerName="Sarah Karkar"
                        locations="Lebanon"
                        icons={process.env.PUBLIC_URL + "/assets/img/testimonials/Sarah-Karkar.jpg"}>
               When I first started my coaching sessions with Shubham, I was extremely low on confidence, I did not believe in myself in almost all aspects of my life! I did not believe in my work as an architect, or in my abilities and aspirations of being a life coach! But Shubham created such a safe space for me to dig deep to where those limitations came from, and helped me push my boundaries and limits and test my comfort zone! And whenever I found myself falling a few steps back, after having our weekly call I would find myself taking so many steps forward! Shubham guided me to a more authentic and confident version of myself, allowing me to move forward as a much stronger person, and for that I am extremely thankful.
                    </ReviewsThumb>

                    <ReviewsThumb
                        reviewerName="Pin Cher"
                        locations="Hong Kong"
                        icons={process.env.PUBLIC_URL + "/assets/img/testimonials/Pin-Cher.jpg"}>
                       Through Shubham’s calm, pragmatic and intelligent approach, I was able to identify my key strengths, interests and passion. I remember feeling scared and nervous about what lay ahead with my career and Shubham helped me to dismantle the obstacles and barriers which were holding me back. He was holding me accountable throughout and with him, I started to create new habits that have helped me to reach my goal of becoming a successful entrepreneur. 
                        <br />
                        I really liked his approach as he made me feel totally supported and safe to fully open about my vulnerabilities. With this, I gained a stronger awareness about my own insecurities in regards to starting my own business. His insights and especially his guidance and recommendations in personal development books have been super helpful and supported me in my confidence level and self-belief to move forward towards my goal. And I can truly say, Shubham is a coach who wants the best in you and supports you in becoming a better version of yourself.
                        <br/>
                        Thank you so much for our coaching sessions. I’d like everyone to experience to gain more clarity and self-awareness as he is the coach to help you to move beyond your insecurities and helps you make positive changes.
                    </ReviewsThumb>

                    <ReviewsThumb
                        reviewerName="Luisa Magalhaes"
                        locations="Portugal"
                        icons={process.env.PUBLIC_URL + "/assets/img/testimonials/Luisa-Magalhaes.jpg"}>
             When I met Shubham, I was already on my own path. Shubham helped me gain deeper self-awareness, get over my doubts & inhibitions, and to better understand my values & priorities. With his help I was able to re-define my goals, broaden my perspective, and discover the solutions to my problems. 
             <br/>
             <br/>
             Sometimes we just cannot see what is in front of us. My journey with Shubham not only helped me uncover some of the hidden answers but also made me feel more confident to accelerate on my path. Thank you Shubham for your help.
                    </ReviewsThumb>

                    
                    <ReviewsThumb
                        reviewerName="Lailah Kigozi"
                        locations="London, UK"
                        icons={process.env.PUBLIC_URL + "/assets/img/testimonials/Lailah-Kigozi.jpg"}>
            I really enjoyed our sessions together and I have learnt a lot. The best thing about the sessions was that after the session, I have always had something of value to carry with me or a breakthrough to reflect upon, which has been very useful. 
                    </ReviewsThumb>


                    <ReviewsThumb
                        reviewerName="Didara Zhumagaliyeva"
                        locations="New York, USA">
          I loved working with Shubham! He is so natural & intuitive and listens deeply & patiently. He is always curious and ready to dig in. He has an amazing energy---I kind of miss our sessions. 
             <br/>
             <br/>
             Also, I felt he gave me the space to think and feel his questions, most of them were powerful as well as insightful. I definitely benefited from our sessions.
                    </ReviewsThumb>

                        </div>
                        <div className="text-center">

                        </div>
            </Sections>
            {/* section */}
          
            </>
    )
}
export default Testimonials
