
import Sections from "../Sections"

const WhyMe = () => {
    return (
        <Sections classes="conLight WhyMe">
            <h2>WHY ME?</h2>
        <div className="flex aic">
            <div>
            <img src={process.env.PUBLIC_URL + "./assets/img/home-page/myPick8.jpg"} alt="shubham agarwal"/>
            </div>
            <div className="flexGrow-1 pdl">
                    <p className="f24">Hi, I’m Shubham.</p>
                    <p><b>Your Purpose & Fulfilment Coach & My Forte is Life & Career Coaching.</b></p>
                <div className="quotes">We are trying so hard either to fit in or to stand out and to do & be everything that’s been told to us. We are not being ourselves. We are not moving at our pace. We are not living in oneness. It's time we bring our inner being to life.
                </div>
            </div>
        </div>
        <p><b>When I am Coaching, I am in Oneness, I am in my Element. Coaching is my Purpose. It gives me absolute Fulfilment. It gives me the Freedom to be my Authentic Self.</b></p>

        <p><b>As a Millennial being brought up in India, having lived the life of an Employee & an Entrepreneur, and having Coached people from different parts of the world & from varied backgrounds,</b> I am well aware of what it feels like struggling to transcend through Insecurities & Self-Doubt and to craft a Fulfilling Career & a Meaningful Life.</p>

        <p> <i>Study shows more than 80% of Millennials are stressed out multiple times a week and 40% of Millennials experience stress every day in their professional & personal life.  </i> </p>

        <p>From my own experiences & from the experiences shared by others, <b>I understand the many adversities & challenges</b> both men and women go through in their lives from childhood through adolescence to adulthood and even old age.</p>

        <p><b>I know what it is like to live with the pain of a Past Trauma, Insecurity, Constant Stress & Frustration, and sometimes even Anxiety, Burnout & Depression</b> because I too have struggled with such pain over the years. For me, most of it came from dealing with a toxic physical environment, heartbreak, impostor syndrome, financial pressure, external validation cravings and from living an inauthentic life, which had its ripple effects on my physical & mental health, career & business, relationships and overall well-being. </p>

        <p>Over the period of this ongoing internal war, I realized this is not the way to live and the only way to be truly free is by being the whole of me. Therefore, I decided to take 100% responsibility for my life. And that’s when I began my personal transformation journey of self-discovery, inculcating healthy habits, studying timeless proven wisdom and learning from coaches & mentors. Things didn’t change overnight but I knew I was on the right path, which is the path of purpose & fulfilment. I started pulling out the weeds from my life one at a time. By letting go of immediate gratification and by doing consistent inner work in the midst of storms, I started seeing the fruits of the seed I planted years ago.</p>

        <div className="flex aic">
            <div className="flexGrow-1 pdr">
                    <p><b>My clients say </b> I am a natural and thank me for making them feel safe to be open & vulnerable and for creating a space for them to share things that they couldn’t with anyone else. They share their experience of being able to uncover their blind spots and broaden their perspective. Moreover, they express their joy of being able to dismantle their roadblocks, connect with their innate authentic self and gain the clarity, confidence & energy to push past their comfort zone & achieve their desired goals.</p>
     
            </div>
            <div>
            <img src={process.env.PUBLIC_URL + "./assets/img/home-page/puzzel.jpg"} alt="shubham agarwal"/>
            </div>
        </div>

        <div className="flex aic">
        <div>
            <i className="i-mission cicon"/>
            </div>
            <div className="flexGrow-1">
                    <p>I am a purpose-driven life empowerment Coach, on a <b>mission</b> to serve driven professionals & fulfilment seekers unlock their latent potential, break through personal barriers, debunk self-limiting beliefs and become a conscious sculptor towards crafting & actualizing an authentically vibrant, purposeful & a fulfilling life.</p>
            </div>
         
        </div>
        <div className="flex aic">
        <div>
            <i className="i-vision cicon"/>
            </div>
            <div className="flexGrow-1">
                    <p><b>I envision</b> a conscious, tolerant, inclusive and thus, a thriving community of conceivers, achievers, givers & collaborators, who are committed to elevating their consciousness and to shining their light onto the world. </p>
            </div>
        </div>
      <p><b>And my mantra to manifesting this vision is –</b></p>
      <p className="progress"> <b> Self-Love + Passion + Service <i className="i-big-arrrow"/> Purpose + Fulfilment + Community </b></p>

      <p><b>I see my clients as the experts & the best sculptors of their lives</b> who have their answers within themselves. My job is to be their mirror and help them uncover those answers by taking them inward so they can gain the clarity and confidence to come up with the solution to their pressing problems and to meet their worthwhile goals. </p>


      <div className="flex aic">
            <div className="flexGrow-1 pdr">
                    <p>Having the zeal to empower fulfilment seekers amplify their well-being, adopt a growth mindset, inculcate positive habits & maximize their potential, I have devoted my energies to transforming lives as a conscious life coach.</p>

                    <p>I know I am living in my Dharma when I see my clients thrive on their <b> Consciousness Metrics of Self-Acceptance, Inner-Liberation, Self-Esteem, Self-Love, Joy, Peace and Fulfilment. </b></p> 
            </div>
            <div>
            <img src={process.env.PUBLIC_URL + "./assets/img/home-page/gowthb.jpg"} alt="shubham agarwal"/>
            </div>
        </div>

        <div className="flex">
            <div className="flexGrow-1 pdr">
                    <p><b>In a nutshell, I’ll be your pillar of strength who will help you pull out the Weeds of Suffering and plant the Seeds of Purpose, by taking you to the root of your pain so you can alchemize it into your power and reap the Fruits of Greatness & Fulfilment.</b></p>

                    <p><b>I am fortunate to have found my purpose and to have stayed on the path of Fulfilment. I have helped my clients add Purpose & Fulfilment to their lives. Let me show you how you can too. </b></p> 
                    <div className="text-center">
    <a href="https://calendly.com/coachingwithshubham/complimentary-discovery-coaching-call" className="link">BOOK YOUR COMPLIMENTARY DISCOVERY CALL</a><br></br>
    <a href="/me" className="link">KNOW MORE ABOUT ME</a>
</div>
            </div>
            <div>
            <img src={process.env.PUBLIC_URL + "./assets/img/home-page/gowth-tree.jpg"} alt="shubham agarwal"/>
            </div>
        </div>

    </Sections>
    )
}

export default WhyMe
