import Sections from "../Sections"

const WorkWith = () => {
    return (
        <Sections classes="conGreenish WorkWith">
<div className="flex aic">
<div>
    <img src={process.env.PUBLIC_URL + "./assets/img/home-page/myPick6.jpg"} alt="shubham agarwal"/>
    </div>
    <div className="flexGrow-1 pdl">
    <h2>WHO DO I WORK WITH?</h2>
                <ul className="list">
                       <li> Aspiring & Mid-Career Professionals</li>
                       <li> Corporate Professionals & Business Executives</li>
                       <li> Entrepreneurs</li>
                       <li> Athletes</li>
                       <li> Artists</li>
                       <li> Job Seekers</li>
                       <li> Students & Recent Graduates</li>
                       <li> Confidence, Purpose & Fulfilment Seekers</li>
                </ul>
                <p>I am inspired to work with those who are willing & determined to take charge of their lives in order to be true to themselves, to their life’s purpose, and thereby, experience their best selves.</p>
    </div>

</div>
</Sections>
    )
}

export default WorkWith
