

import PageBanner from "../../components/PageBanner"
import Sections from "../../components/Sections"
const GlobalCodeEthics = () => {
    return (
        <>
            <PageBanner pagename="Introduction And Purpose" />

            {/* section */}
            <Sections classes="speciclHedeng">
                <h2 className="text-center">ASSOCIATION FOR COACHING GLOBAL CODE OF ETHICS
                    BY GLOBAL COACHING MENTORING ALLIANCE (GCMA)
                    For Coaches, Mentors and Supervisors
                </h2>
            </Sections>
            {/* section */}
            <Sections classes="WhyMe conGreenish">
                <h2>Introduction and Purpose</h2>
                <p>All the bodies (“the bodies”) mentioned in section 5, as signatories to this Code of Ethics (“the Code”), wish to state the following:</p>

                <p>As membership bodies, we are committed to maintaining and promoting excellent practice in coaching, mentoring and supervision, a field that is becoming increasingly professionalised. All our members, in their roles as coaches, mentors, supervisors, trainers and/or students, as part of their continuing membership, agree to adhere to the elements and principles of this Code of Ethics.</p>

                <p>The Code aligns with the content and requirements set out in the Professional Charter for Coaching and Mentoring. The Charter, drafted in accordance with European law, is registered on the dedicated European Union database, which lists self-regulation initiatives in Europe.</p>

                <p>The Code is a guidance document rather than a legally binding one that in detail spells out what a member can and cannot do. The Code sets the expectation of best practice in coaching, mentoring and supervision promoting the development of professional excellence. Its purpose is to:</p>

                <ul className="list">
                    <li>Provide appropriate guidelines, accountability and standards of conduct for all our members</li>

                    <li>Set out how our members are expected to act, behave and perform when working with clients</li>

                    <li>In conjunction with our respective bodies' professional competences, guide our members' development and growth in the profession</li>

                    <li>Serve as a guide for those individuals who do not necessarily identify themselves as a professional coach or mentor, but nonetheless use coaching or mentoring skills in their work</li>

                    <li>Be used as the basis of any complaint or disciplinary hearing and action following our bodies’ respective complaints procedures.</li>

                </ul>

                <p>Each signatory to the Code may decide that – in creating a complete ethics-related framework specific to their membership – it needs additional ethical principles and/or a code of practice (to reflect context, activity, membership criteria, membership structure, etc.) to complement the Code.</p>

                <p>Such additions must not contradict the essence of the Code, nor carry an obligation for the other co-signatories to adopt. Such additions may be shared with the other co-signatories as part of the ongoing collaborative exchanges between professional bodies.</p>

                <h2>The Code of Ethics</h2>
                <p>The Code is arranged into five sections and covers the bodies’ general expectations of professional behaviour and conduct: </p>
                <ol className="list reab ol">
                    <li>Terminology</li>
                    <li>Working with Clients </li>
                    <li>Professional Conduct</li>
                    <li>Excellent Practice</li>
                </ol>

                <h2>1. Terminology</h2>

                <p>For reasons of brevity this Code where appropriate refers to:</p>
                <ul className="list">
                    <li>Coachees, mentees, supervisees and students as “clients”</li>

                    <li>Coaches, mentors, supervisors and trainers as “practising members” or “members”</li>

                    <li>Coaching, mentoring and supervision work as “professional work”</li>

                    <li>Coaching, mentoring and supervision as “profession”.</li>

                    <li>The signatories to this Code acknowledge that the terms “profession” and “professional” are being used for activities that are not under statutory regulation but are being increasingly professionalised and self-regulated.</li>

                    <li>The signatories to this Code acknowledge that the titles “coach”, “mentor” and “supervisor” are not protected and may be used by anyone in the field of practice, member or not member of a professional body.</li>

                    <li>Each signatory will define exactly which of its members and other stakeholders are expected to abide by this Code (who are hereafter collectively referred to as the "members").</li>

                    <li>For proper understanding of this Code members should be aware of their respective professional body’s definitions and terminology for the precise meanings of key words used in this Code e.g. coach, coaching, client, member, mentor, mentoring, sponsor, supervisor, supervision and training</li>
                </ul>
                <h2>2. Working with Clients</h2>
                <h5>Context</h5>
                <p>
                    <b>2.1</b> &nbsp; When professionally working with clients in any capacity members will conduct themselves in accordance with this code, committed to delivering the level of service that may reasonably be expected of a practising member.
                </p>
                <h5>Contracting</h5>
                <p>
                    <b>2.2</b> &nbsp; Before they start working with a client, members will make this Code available to their client, and explain and make explicit, their commitment to abide by this Code.  Members will also make their clients and sponsors aware of their respective bodies’ complaints procedures.
                </p>

                <p>
                    <b>2.3</b> &nbsp; Before starting to work with a client, members will explain and strive to ensure that the client and sponsor know, and fully understand, the nature and terms and conditions of any coaching, mentoring or supervision contract, including financial, logistical and confidentiality arrangements.
                </p>

                <p>
                    <b>2.4</b> &nbsp; Members will use their professional knowledge and experience to understand their clients’ and sponsors’ expectations and reach agreement on how they plan to meet them.  Members will also try to take into account the needs and expectations of other relevant parties.
                </p>

                <p>
                    <b>2.5</b> &nbsp; Members will be open about the methods they use, and on request will be ready to supply the client and sponsor with information about the processes involved.
                </p>

                <p>
                    <b>2.6</b> &nbsp; Members will ensure that the duration of the contract is appropriate to achieve the client’s and sponsor’s goals and will actively work to promote the client’s independence and self-reliance.
                </p>

                <p>
                    <b>2.7</b> &nbsp; Members will ensure that the setting in which any coaching, mentoring, supervision or training takes place offers optimal conditions for learning and reflection and therefore a higher likelihood of achievement of the goals set in the contract.
                </p>

                <p>
                    <b>2.8</b> &nbsp; Members should always put their client’s interests first but at the same time safeguard that these interests do not harm the interests of the sponsor.
                </p>

                <h5>Integrity</h5>

                <p>
                    <b>2.9</b> &nbsp; Members will accurately and honestly represent their relevant professional qualifications, professional body to which they belong, experience, training, certifications and accreditations to clients, sponsors and colleagues.
                </p>

                <p>
                    <b>2.10</b> &nbsp;  In communication with any party, members will accurately and honestly represent the value they provide as a coach, mentor or supervisor.
                </p>

                <p>
                    <b>2.11</b> &nbsp; Members will ensure that no false or misleading claims are made, or implied, about their professional competence, qualifications or accreditation in any published, promotional material or otherwise. Members will attribute ownership of work, ideas and materials of others to the originator and not claim it as their own.
                </p>

                <p>
                    <b>2.12</b> &nbsp; Members will act within applicable law and not in any way encourage, assist or collude with conduct which is dishonest, unlawful, unprofessional or discriminatory.
                </p>

                <h5>Confidentiality</h5>

                <p>
                    <b>2.13</b> &nbsp; When working with clients, members will maintain the strictest level of confidentiality with all client and sponsor information unless release of information is required by law.
                </p>
                <p>
                    <b>2.14</b> &nbsp; Members will have a clear agreement with clients and sponsors about the conditions under which confidentiality will not be maintained (e.g. illegal activity, danger to self or others) and gain agreement to that limit of confidentiality where possible unless the release of information is required by law.
                </p>
                <p>
                    <b>2.15</b> &nbsp; Members will keep, store and dispose of appropriate and accurate records of their work with clients, including electronic files and communications, in a manner that ensures confidentiality, security and privacy, and complies with all relevant laws and agreements that exist in their country regarding data protection and privacy.

                </p>
                <p>
                    <b>2.16</b> &nbsp; Members will inform clients that they are receiving supervision and identify that the client may be referred to anonymously in this context.  The client should be assured that the supervision relationship is itself a confidential relationship.

                </p>
                <p>
                    <b>2.17</b> &nbsp;  If the client is a child or vulnerable adult, members will make arrangements with the client’s sponsors or guardian to ensure an appropriate level of confidentiality in the best interests of the client, whilst also complying with all relevant legislation.
                </p>

                <h5>Inappropriate interactions</h5>
                <p>
                    <b>2.18</b> &nbsp;  Members are responsible for setting and maintaining clear, appropriate and culturally sensitive boundaries that govern all physical and virtual interactions with clients or sponsors.
                </p>
                <p>
                    <b>2.19</b> &nbsp;  Members will avoid any romantic or sexual relationship with current clients or sponsors. Further, members will be alert to the possibility of any potential sexual intimacy with the aforementioned parties and take appropriate action to avoid the intimacy or cancel the engagement in order to provide a safe environment.
                </p>

                <h5>Conflict of interest</h5>
                <p>
                    <b>2.20</b> &nbsp;  Members will not exploit a client or seek to gain any inappropriate financial or non-financial advantage from the relationship.
                </p>
                <p>
                    <b>2.21</b> &nbsp;  To avoid any conflict of interest, members will distinguish a professional relationship with a client from other forms of relationships.
                </p>
                <p>
                    <b>2.22</b> &nbsp;  Members will be aware of the potential for conflicts of interest of either a commercial or personal nature arising through the working relationship and address them quickly and effectively in order to ensure that there is no detriment to the client or sponsor.
                </p>
                <p>
                    <b>2.23</b> &nbsp;  Members will consider the impact of any client relationships on other client relationships and discuss any potential conflict of interest with those who might be affected.
                </p>
                <p>
                    <b>2.24</b> &nbsp; Members will disclose any conflict openly with the client and agree to withdraw from the relationship if a conflict arises which cannot be managed effectively.
                </p>

                <h5>Terminating professional relationships and on-going responsibilities</h5>
                <p>
                    <b>2.25</b> &nbsp; Members will respect a client's right to terminate an engagement at any point in the process, subject to the provisions of the coaching, mentoring or supervision service agreement.

                </p>
                <p>
                    <b>2.26</b> &nbsp; Members will encourage the client or sponsor to terminate the coaching, mentoring or supervision engagement if it is believed that the client would be better served by another practising member or a different form of professional help.

                </p>
                <p>
                    <b>2.27</b> &nbsp; Members understand that their professional responsibilities continue beyond the termination of the professional relationship. These include:
                </p>
                <ul className="list">
                    <li>Maintenance of agreed confidentiality of all information relating to clients and sponsors</li>
                    <li>Safe and secure maintenance of all related records and data that complies with all relevant laws and agreements that exist in their country regarding data protection and privacy</li>
                    <li>Avoidance of any exploitation of the former relationship, which could otherwise call into question the professionalism or integrity of the member or the professional community</li>
                    <li>Provision of any follow-up that has been agreed to.</li>
                    </ul>
                    <p>
                    <b>2.28</b> &nbsp; Members are required to have a provision for transfer of current clients and dissemination of records in the event of the member’s incapacitation, or termination of practice.
                </p>

                <h2>3. Professional Conduct</h2>
                <h5>Maintaining the reputation of the profession</h5>
                <p>
                    <b>3.1</b> &nbsp; Members will behave in a way that at all times reflects positively upon and enhances the reputation of an increasingly professionalised service.
                </p>
                <p>
                    <b>3.2</b> &nbsp; Members will demonstrate respect for the variety of practising members and other individuals in the profession and for the different approaches to coaching, mentoring and supervision.
                </p>
                <h5>Maintaining the reputation of the profession</h5>
                <p>
                    <b>3.3</b> &nbsp; Members will abide by their respective bodies’ diversity statements and policies.
                </p>
                <p>
                    <b>3.4</b> &nbsp; Members will avoid knowingly discriminating on any grounds and will seek to enhance their own awareness of possible areas of discrimination.
                </p>
                <p>
                    <b>3.5</b> &nbsp; Members will be cognisant of the potential for unconscious bias and seek to ensure that they take a respectful and inclusive approach, which embraces and explores individual difference.

                </p>
                <p>
                    <b>3.6</b> &nbsp;  Members will challenge in a supportive way any colleagues, employees, service providers, clients or participants who are perceived to be using discriminatory behaviour.

                </p>
                <p>
                    <b>3.7</b> &nbsp;  Members will monitor their spoken, written and non-verbal communication for inadvertent discrimination.
                </p>
                <p>
                    <b>3.8</b> &nbsp;  Members will engage in developmental activities that are likely to increase their self- awareness in relation to equality and diversity.

                </p>

                <h5>Breaches of professional conduct</h5>
                <p>
                    <b>3.9</b> &nbsp;  Members will engage in developmental activities that are likely to increase their self- awareness in relation to equality and diversity.

                </p>
                <p>
                    <b>3.10</b> &nbsp;  Members will engage in developmental activities that are likely to increase their self- awareness in relation to equality and diversity.
                    </p>

                <h5>Legal and statutory obligations and duties</h5>
                <p>
                    <b>3.11</b> &nbsp;  Members are obliged to stay up to date and comply with all relevant statutory requirements in the countries in which their professional work takes place and work within any organisational policies and procedures in the context in which they are working.

                </p>
                <p>
                    <b>3.12</b> &nbsp;  Members will have the appropriate professional indemnity insurance to cover their coaching, mentoring and supervising work for the countries in which they operate.
                  </p>  
                    <h2>4. Excellent Practice</h2>
                  <h5>Ability to perform</h5> 
                  <p>
                    <b>4.1</b> &nbsp;  Members will have the qualifications, skills and experience appropriate to meet the needs of the client and will operate within the limits of their competence. Members should refer the client to a more experienced or suitably qualified practising member where appropriate.
                 </p>
                 <p>
                    <b>4.2</b> &nbsp;  Members will be fit and healthy enough to practice. If they are not, or are unsure if they are able to practice safely for health reasons, they will seek professional guidance or support. Where necessary or appropriate, the practising member should manage the termination of their work with the client and refer the client to an alternative practising member.

                 </p>
                 <h5>On-going supervision</h5>
                 <p>
                    <b>4.3</b> &nbsp;  Members will engage in supervision with a suitably qualified supervisor or peer supervision group with a level of frequency that is appropriate to their coaching, mentoring or supervision practice, the requirements of their professional body and the level of their accreditation, or evidence engagement in reflective practice, ideally with peers and/or more experienced colleagues.
                 </p>
                 <p>
                    <b>4.4</b> &nbsp;  Members need to ensure that any other existing relationship with the supervisor does not interfere with the quality of the supervision provided.
                 </p>
                 <p>
                    <b>4.5</b> &nbsp;  Members will discuss any ethical dilemmas and potential, or actual, breaches of this Code with their supervisor or peer supervision group for support and guidance.
                     </p>

                    <h5>Continuing professional development</h5>
                     <p>
                    <b>4.6</b> &nbsp;  Members will develop their level of coaching and/or mentoring competence by participating in relevant and appropriate training and/or continuing professional development (CPD).
                     </p>

                     <p>
                    <b>4.7</b> &nbsp;  Members are expected to make a contribution to the professional community that is appropriate to their level of expertise.  Forms which this may take include informal peer support to fellow practising members, contributing to advancing the profession, research and writing etc.
                     </p>

                     <p>
                    <b>4.8</b> &nbsp;  Members will systematically evaluate the quality of their work through feedback from clients, their supervisor and other relevant parties.
                     </p>

            </Sections>


        </>
    )
}
export default GlobalCodeEthics