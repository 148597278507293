

import PageBanner from "../../components/PageBanner"
import Sections from "../../components/Sections"
const LifeCoachingUncovered= () => {
    return (
        <>
            <PageBanner pagename="LIFE COACHING UNCOVERED"/>

            {/* section */}
            <Sections classes="WhyMe">
            <h2>LIFE COACHING (WHAT & WHY)?</h2>
                <div className="flex col-sm-reverse">
                <div className="flexGrow-1 pdr">
                <p>Coaching is a highly impactful & effective way of empowering & serving the clients broaden perspective, remove roadblocks, stay outcome-focused, be accountable and find their own answers so they can consciously connect with their innate spark, maximize their potential and thrive towards actualising an authentically vibrant, meaningful & a fulfilling life. </p>

<p>Life coaching addresses various areas of life, from career development to health, relationships, personal growth, and spirituality. The positive impact created on one area of your life, ripples not only on the other areas but also benefits other people in your circle.   </p>

<p>Coach-client relationship is a solution-focused and creative partnership laid on the foundation of ethics, trust and transparency.  It is the journey taken together that determines the starting point & the way forward, generates insights on both sides and transforms both the client and the coach. </p>
                </div>
                <div>
                <img src={process.env.PUBLIC_URL + "/assets/img/services/coaching.jpg"} alt="shubham agarwal" />
                
                </div>

                </div>

                <div className="flex col-sm-reverse">
                <div className="flexGrow-1 pdr">

<p>Coaching is oriented not merely on fixing problems but more importantly on harnessing potential. A life coach sees clients as the experts & the best sculptors of their own life, who have their answers within themselves. The job of a coach is to help people uncover those answers hidden deep down within so they can gain the clarity and confidence to solve their pressing challenges and meet their worthwhile goals.</p>

<p>Basically, coaching is focused on identifying where you are now, where you want to be and then supporting you to close that gap.</p>

<p>Coaching is more powerful than changing lives; it shows people how to change their own lives. It has the potential to move past regional and cultural boundaries, transforming society. </p>
                </div>
                <div>
                <img src={process.env.PUBLIC_URL + "/assets/img/learn/changes.jpg"} alt="shubham agarwal" />
                
                </div>

                </div>
            
    
            </Sections>
            {/* section */}
            <Sections classes="WhyMe conGreenish">
            <h2>WHO IS A LIFE COACH?</h2>
                <div class="flex col-sm-reverse">
                    <div className="flexGrow-1 pdr">
                        <ul className="list">
                    <li> A trusted source of guidance to help clients explore & discover their answers within themselves and to facilitate them meet their worthwhile conscious goals.</li>

                    <li>A thought-partner and a catalyst in clients’ growth journey of discovering their best authentic selves and crafting a fulfilling life.  </li>

                    <li>Helps clients see something amazing in them that they are oblivious of or afraid to see.
                        Stimulates out-of-the box thinking.
                    </li>

                    <li>Facilitates performance enhancements, accountability, self-directed learning, personal growth and enriching life experiences.  </li>

                    <li>Helps clients make crucial professional and personal decisions consciously & wisely. </li>

                    <li>Recognizes the client as the expert and master of his or her own life. </li>
                    <li>Knows & appreciates that the client is whole, unique, creative, resourceful & worthy and with appropriate guidance can create their own masterpiece. </li>
                    <li>Helps clients maximize their potential at their own pace.</li>
                    <li>Anticipates and prepares clients for challenges and opportunities. </li>
                    <li>Shares tools & strategies and monitors progress.</li>
                    <li>Have a good understanding of people’s thoughts, feelings and behaviours. </li>
                </ul></div>
                    <div>
                    <img src={process.env.PUBLIC_URL + "/assets/img/home-page/coach.jpg"} alt="shubham agarwal" />  
                    </div>
                </div>
            </Sections>
                     {/* section */}
            <Sections classes="WhyMe">
            <h2>HOW IS COACHING DIFFERENT FROM MENTORING, CONSULTING AND THERAPY?</h2>
            <p>Although these four distinct professions assist clients achieve their outcome and sometimes work in collaboration, it is important to understand how these services differ from each other.</p>
            <p>While coaching is about helping clients facilitate their own change, other forms of assistance are more about doing something for the client.</p>

                <div class="row column">
                    {/* colum */}
                    <div className="col-5">
                        <p><b>CONSULTANTS</b></p>
                    </div>
                    <div className="col-7">
                        <p><b>COACHES</b></p>
                    </div>
                      {/* colum */}
                      <div className="col-5">
                        <p>Subject-matter experts focused on finding & implementing the solution to the client's problem.</p>
                    </div>
                    <div className="col-7">
                        <p>Help clients raise insights and guide them figure out their own solutions by using client-focused coaching techniques, tools & strategies.</p>
                    </div>
                      {/* colum */}
                      <div className="col-5">
                        <p>Take the load off of their clients’ shoulders and run & manage activities on the behalf of their clients.</p>
                    </div>
                    <div className="col-7">
                        <p>Support clients form success habits and take progressive action steps towards meeting their goals.</p>
                    </div>
                      {/* colum */}
                      <div className="col-5">
                        <p>Require little commitment from the client.</p>
                    </div>
                    <div className="col-7">
                        <p>Require full presence and commitment from the client.</p>
                    </div>
                      {/* colum */}
                      <div className="col-5">
                        <p>Learn, explore & execute on behalf of the client.</p>
                    </div>
                    <div className="col-7">
                        <p>Facilitate client’s self-directed learning.</p>
                    </div>
                      {/* colum */}
                          <div className="col-5">
                        <p>Do not try to transform their clients.</p>
                    </div>
                    <div className="col-7">
                        <p>Focused on transforming their clients.</p>
                    </div>
                      {/* colum */}
                      <div className="col-5">
                        <p>Responsible for the client's outcome.</p>
                    </div>
                    <div className="col-7">
                        <p>Empower clients to be responsible for their own success.</p>
                    </div>
                   </div>
          

                <div class="row column">
                    {/* colum */}
                    <div className="col-5">
                        <p><b>MENTORS</b></p>
                    </div>
                    <div className="col-7">
                        <p><b>COACHES</b></p>
                    </div>
                      {/* colum */}
                      <div className="col-5">
                        <p>Advise their mentee to get a point where they are currently at by sharing their learning experiences.</p>
                    </div>
                    <div className="col-7">
                        <p>Do not necessarily have experience in the same area as that of their coachee. Rather, they apply holistic and client-focused processes, techniques & principles.</p>
                    </div>
                      {/* colum */}
                      <div className="col-5">
                        <p>Tell the client what to do.</p>
                    </div>
                    <div className="col-7">
                        <p>Ask open-ended reflective questions to help the clients raise insights and come up with their own answers.</p>
                    </div>
                      {/* colum */}
                      <div className="col-5">
                        <p>Mentoring is a more casual and informal relationship.</p>
                    </div>
                    <div className="col-7">
                        <p>Coaching is a formal approach of turning expectations into contracting with planned sessions, schedules, specified goals, monitoring & evaluation.</p>
                    </div>
                      {/* colum */}
                      <div className="col-5">
                        <p>Mentoring is more like a parent-child relationship</p>
                    </div>
                    <div className="col-7">
                        <p>Coaching is an equal-footing adult-to-adult collaboration</p>
                    </div>
                  </div>
      

                  <div class="row column">
                    {/* colum */}
                    <div className="col-6">
                        <p><b>THERAPISTS</b></p>
                    </div>
                    <div className="col-6">
                        <p><b>COACHES</b></p>
                    </div>
                      {/* colum */}
                      <div className="col-6">
                        <p>Take their clients from dysfunctional to functional state.</p>
                    </div>
                    <div className="col-6">
                        <p>Take their clients from functional to phenomenal state.</p>
                    </div>
                      {/* colum */}
                      <div className="col-6">
                        <p>See the client as a victim. Diagnose and treat abnormalities.</p>
                    </div>
                    <div className="col-6">
                        <p>See clients as creators and empower them to challenge their comfort zone.</p>
                    </div>
                      {/* colum */}
                      <div className="col-6">
                        <p>Medical or psychological professionals holding a master’s or doctorate.</p>
                    </div>
                    <div className="col-6">
                        <p>Trained professionals certified or accredited by an internationally recognized & approved coaching organization.</p>
                    </div>
                      {/* colum */}
                      <div className="col-6">
                        <p>Clients exhibit signs of mental health issues such as violence, substance abuse, serious depression & trauma or developmental disorders.</p>
                    </div>
                    <div className="col-6">
                        <p>Psychologically fit clients seeking support to find solutions and excel in a given area(s) of their life.</p>
                    </div>
                      {/* colum */}
                          <div className="col-6">
                        <p>Therapy is about healing and recovering.</p>
                    </div>
                    <div className="col-6">
                        <p>Coaching is about discovering and thriving.</p>
                    </div>
                     
                   </div>
                   
            </Sections>

              {/* section */}
              <Sections classes="WhyMe">
            <h2>COMMON MYTHS ABOUT LIFE COACHING</h2>
            <div class="flex aic col-sm-reverse">
    <div className="flexGrow-1 pdr">
    <ul className="list">
                   <li> Coaches need to be subject-matter experts</li>
                   <li> Coach provides answers to clients’ problems.</li>
                   <li> Coaching is expensive. Only a few fortunate ones can afford it.</li>
                   <li> Coaching is only for the experienced, top business executives, leaders, seasoned entrepreneurs, celebrities and politicians.</li>
                   <li> Successful people don’t need coaches.</li>
                   <li> Coaching is a quick-fix, one-size fits all approach for client’s challenges.</li>
                   <li> Coaches hurry growth along.</li>
                   <li> Coaches compare clients to themselves.</li>
                   <li> Coaching is a new form of therapy or counselling.</li>
                   <li> Coaches give advice to and imposes their own ideals & expectations on the client.</li>
                   <li> Coach prescribes goals and decisions.</li>
                   <li> Clients need to have their life mapped out before hiring a coach.</li>
                   <li> Clients should hire a coach for a year.</li>

            </ul>
    </div>
    <div>
    <img src={process.env.PUBLIC_URL + "/assets/img/learn/mythes.png"} alt="shubham agarwal" />
    
    </div>
</div>

 </Sections>
            </>
    )
}
export default LifeCoachingUncovered
