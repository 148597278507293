import Sections from "../Sections"

const MypickTwo = () => {
    return (
     <Sections classes="qoute mypick2 ">
         <div className="flex aib">
             <div>
             <img src={process.env.PUBLIC_URL + "./assets/img/home-page/myPick2.jpg"} alt="shubham agarwal"/>
             </div>
             <div className="flexGrow-1 pdl">
                 <div className="quotes">Behind every champion is a coach who refused to let them be anything but the best they can be.</div>
             </div>
         </div>
     </Sections>
    )
}

export default MypickTwo
