
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"

const Me = () => {
    return (
        <>
            <PageBanner pagename="Me" />
            <Sections classes="qoute collaboration meShubham">
                <div className="flex">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/me/myPick10.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl">
                        <div className="quotes"><main className="qlefts">The secret of life is not the ‘Law of Attraction’; it is the ‘Law of Radiation’. When we radiate our authentic energy vibrations by expressing our true selves & by serving others, the universe gives us more of exactly who we are so we can be and have in order to experience our greater evolved selves.</main> <br /><br />
                            We attract what we radiate. That’s the truth of life.</div>  <br /><br />

                            <p className="f24"><b>SHUBHAM AGARWAL</b></p>
                <h3>Purpose & Fulfilment Coach</h3>
                <p className="tags">
                    <span>Jay Shetty Certified Life & Success Coach</span>
                    <span>Member of Association for Coaching</span>
                    <span>Growth Enabler</span>
                    <span>Wellness Advocate</span>
                    <span>Sports Enthusiast</span>
                    <span>Certified Tennis Coach</span>
                    <span>Business Advisor</span>
                    <span>Former Tech Entrepreneur</span>
                    <span>Former Business Consultant</span>
                     </p>
                <h3>ME IN 3 WORDS</h3>
                <p>Calm. Confident. Caring.</p>
                    </div>
                </div>
         
            </Sections>
            <Sections classes="conLight conGreenish WhyMe">
                <p>After much self-reflection and self-assessment, I have come to realise the purpose of my life as being of service through the path of coaching.</p>

                <p>Having been through a roller-coaster journey, I’m thrilled & proud to be able to say with purpose & peace that
                    <b> I’m a Coach by profession and also at heart.</b></p>

                <p>I could keep it formal & polished but being raw & authentic has become my thing. So, I’m going to be real and honest with you. </p>

                <p>For me, success is not measured by how much money or fame I have acquired, but by how many lives are better off and what is their level of health, happiness & well-being because of me. Success is Inner Peace. Success is Fulfilment. </p>

                <p>Don’t misunderstand me, I’m not disregarding the worth of money or recognition. Both are extremely important.  What I mean is they are not the end goal, instead the catalyst that helps us get to our end goal. Money & Recognition are by-products of the service we provide to others. They are the metrics of the value we create for individuals & organizations. </p>

                <p>I am grateful to have finally come to a point in my life where I am set out on a lifelong fulfilment journey of elevating my consciousness, living my purpose and being my ever-evolving authentic self.</p>
                <p> It has taken me years of inner work to be able to say, “I am Enough. I am Whole. I am Worthy. I am Resourceful.” </p>
                <p>I am fortunate to have rediscovered the person I was always meant to be.</p>

            </Sections>

            <Sections classes="conLight WhyMe">
                <p><b>Most people know me as an Engineering Graduate turned Tennis Coach, Entrepreneur and Life Coach</b>, who went against the norms defying the odds to follow his passion, live his purpose and to do what he believes in. </p>

                <p><b>This is what my friends say about me - </b>
                    energetic, flexible, dependable, thorough, confident, adventurous, risk-taker, humble, generous, trustworthy, observant, patient and a listener.  And I don’t disagree with them.</p>

                <p>I was an insecure overweight kid who turned himself into a fit & confident guy.</p>

                <p>I have become more accepting, appreciative and expressive over the years. </p>

                <p>I am the kind of person who never exaggerates nor gets easily astounded. </p>

                <p>It took me a while to realize that my worth is not based on external validation, rather, it purely stems from an internal approval of doing the right thing well. That’s when I started experiencing Freedom & Fulfilment.</p>

                <p>When I sign up for a job or enter into a partnership, I go all in and justify it with total commitment & responsibility leaving no stone unturned. I don’t know how to do things half-heartedly. By being conscientious and by knowing that I have given my 100% & have lived up to my own standards, I gain the much needed inner peace at the end of the day. And now I consciously ensure that my work and my way of life must resonate with my passion, my purpose and my values, so the journey itself becomes fulfilling and I have nothing to regret irrespective of the outcome. </p>
                <p>I am a big time nature lover and don’t miss a day of taking a stroll to get recharged by its healing powers. </p>
                <p>For me sports & fitness, travelling, music, collaborating, learning, sharing & serving are the key aspects to experiencing life to its fullest.</p>
            </Sections>

            <Sections classes="conLight conGreenish WhyMe">
                <h2>What most people don’t know about me is that…</h2>
                <p>I’ve been bullied. I’ve been shamed. I’ve been deceived. I’ve struggled financially. I’ve felt lonely. I’ve been directionless, confused & depressed. I’ve been stuck in a toxic environment.</p>
                <p>I am an introvert who is reluctant to actively participate in events unless he sees a purpose in it that aligns with his passion & values. It took me a while to realize that introversion is not my weakness but a powerful attribute I possess owing to which I have rediscovered myself, have formed some deep meaningful connections and have been able to filter out the noise.</p>
                <p>Though I am great at helping others, I am not very good at asking for help or showing my feelings.  </p>
                <p>I am not perfect. I’m guilty of :  </p>
                <ul className="list">
                    <li>Ignoring my inner calling for years.</li>
                    <li>Pleasing others and trying to fit in, even at the expense of my own well-being.</li>
                    <li>Letting the opinions and expectations of others curb my inner voice.</li>
                    <li>Going against my authentic being & failing to express my true self.</li>
                    <li>Giving others the power to write my story.</li>
                    <li>Making money as my No.1 goal and letting my passion & purpose take the 2nd place.</li>
                    <li>Prioritizing others over myself.</li>
                    <li>Seeing self-care as being selfish.</li>
                    <li>Compromising my physical & mental health.</li>
                    <li>Rushing through everything.</li>
                    <li>Losing myself by years of mindless toiling in school, college and at work.</li>
                    <li>Ignoring my passion for a supposedly glorified career.</li>
                    <li>Depriving myself of purpose & fulfilment.</li>
                </ul>
                <p>But I do not regret these mistakes today, instead I’m glad that I have been through these challenging life-altering experiences as they have taught me some very important life lessons and helped me un-become the person I am not. If not for these experiences, I probably would not have started the inward journey of self-discovery and would not have begun the unlearning process. Moreover, I would not have reconnected with my passion & my purpose and been on the path of fulfilment. </p>
                <p>Through these experiences, I have become fairly comfortable with pushing past my comfort zone and more determined to bring out the best in me, to keep evolving, and to live a purposeful, expressive & fulfilling life. Moreover, I’ve learned to embrace the process, have fun along the way & live mindfully. Finally, I’ve realized being vulnerable is a great strength. It is the road to liberation and a courageous act of service inspiring others to be free. </p>
                <div className="quotes text-center centeQuote">
                    Vulnerability is The Gift of Absolute Freedom. Everyone has it but only a few blessed ones use it.
                    <br /> <br />
                    Add Self-Discovery to your doubts & fears and you too can experience the freedom to be vulnerable.
                </div>
                <p>Whenever I observe myself deviating from my nature, I immediately make corrective amendments without being too hard on myself. I could do so because I’ve tasted the power of authenticity, I’m not afraid to introspect and I don’t have an ego big enough to think I am perfect.  </p>
                <p>That’s why I have never lost the ability to look in the mirror. That’s how I sleep well.</p>
            </Sections>

            <Sections classes="qoute purquote2">
                <div className="quotes">The true meaning of life is to plant trees, under whose shade you do not expect to sit.</div><p className="text-center"><b>- Nelson Henderson</b></p>
            </Sections>

            <Sections classes="conLight conGreenish collaboration">
              <div className="flex">
                  <div>
                  <img src={process.env.PUBLIC_URL + "./assets/img/me/myPick11.jpg"} alt="shubham agarwal" />
                  </div>
                  <div className="flexGrow-1 pdl">
                      <p>For me, life is about purpose & fulfilment, which comes from self-love, passion and service. </p>
                      <p>My <b>purpose</b> is to serve & empower driven souls & professionals fulfil their highest potential, achieve greatness and experience joy & inner liberation by being their guiding & facilitating partner in their authentic & meaningful journey of self-discovery & self-actualization. </p>

                      <p>When I look back at my life, I realize I have had the natural inclination to encourage & support those who strive to give their 100% every time and learn to embrace the process, irrespective of whatever the outcome may be. At the same time, I have always been empathic and compassionate towards those who are feeling weak & directionless. Most importantly, I have always had the burning desire to effect change when something is going terribly wrong such as inequity & oppression. </p>
                  </div>
              </div>
              <p>My<b> former career chapters</b> were marked by over a decade of evolving experiences in the Sports Business and Startup Ecosystem, where I have served communities by fostering inclusivity, well-being and socio-emotional as well as economic growth as a novice, team player, entrepreneur, consultant, educator, coach and collaborator.</p>

                <p>Moreover, I have contributed towards creating an inclusive work environment, offering excellent client satisfaction, inspiring leadership development, building motivated teams, resolving conflicts, forming key alliances, and having sustainable growth, keeping the core focus on building and having trusted relationships.</p>

                <p>With a zeal to share inspirational stories, empower growth mindset and foster camaraderie, I’ve co-created a Canadian Talk Show called The Startup Impact, featuring some insightful & inspiring stories for collective personal and professional development.</p>

                <p>As a passionate sportsperson, tennis coach, former event organizer, and a conscious wellness advocate, I have been guiding & motivating children and youths to acquire self-confidence & essential life skills through sports and I strongly advocate the practice of sports & arts for the development of a healthy and a conscious being.</p>

            </Sections>

            <Sections classes="conLight collaboration">
                <div className="flex">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/me/myPick12.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl">
                        <h2>I DISCOVERED MY CALLING 13 YEARS AGO </h2>
                        <p>My first big sensation of anxiety, heaviness and emotional restlessness kicked in when I was a sophomore in engineering college. I’ve been feeling off for a while and at the end of semester, I was terribly out of alignment with my internal being, realizing I was not carved to be a software engineer and all this while I have been forcing myself to fit in & to please everyone else. I have always been passionate about sports & fitness and loved playing Tennis, even to this day. I also enjoyed teaching and helping others. So, after a few weeks of introspection, I decided to give myself a shot at becoming a tennis player. I knew it’s not going to be easy by far but every cell in my body was telling me it is the right thing to do and the process itself is going to be extremely fulfilling. And so the journey began. </p>
                    </div>
                </div>
                <p>Fast forward, I turned out to be a decent tennis player (not a pro) with a college degree by my side. One thing I knew for sure then was that I am not taking a job as a software engineer. So, I combined my passion for tennis with my burning desire to guide children & teenagers realize their true potential and live in their element. And through some inevitable challenges and with much determination, I started my career as a tennis coach and became professionally certified. That’s how I started expressing my passion and serving my purpose. That’s how I made my work my play. That’s how I started making a difference in the lives of others. </p>

                <p>With a natural inclination & curiosity to explore and diversify, I went on wearing various hats in my career over the years from tennis coaching to event management, business development, operations, project management, to business consulting and entrepreneurship. Let me tell you, it has not been smooth sailing. Although I have had my share of personal and professional achievements, they didn’t come easy and were marked by stumbling blocks & setbacks. I fell many times, for which I am grateful today. And probably that’s why I became fascinated with personal development and started seeking my deeper purpose. I went on a transformational journey of elevating my consciousness through introspection & mindfulness, by learning from spiritual, business & life coaches, by absorbing the timeless proven wisdom. </p>

                <p><b>If you are wondering what got me through to being who I am today,</b> it has been my fear of regret of dying without being able to fulfil my true potential and purpose. This single cause has been the fuel for my self-belief, drive & determination that helped me defy the odds, persevere and hit my milestones.  </p>
            </Sections>

            <Sections classes="white-text collaboration watermark reconnected">
                <h2>I RECONNECTED WITH MY SOUL’S PURPOSE.</h2>
                <p>Over a decade had passed and once again I started having a similar sensation of being out of alignment with my element. I knew something was definitely missing as I didn’t feel being in oneness. But this time I had the experience and some wisdom in my belt.</p>

                <p>After months of reflection & research, I, in 2020, took the plunge into the world of Life Coaching and in the process, I realized this is what I am supposed to be doing, this is what my life experiences were preparing me for all this while.</p>

                <p>And here I am, a fulfilment-driven Life Coach & Jay Shetty Certification School Graduate, making a purpose-driven impact on the life of others & self. This is just the beginning and I am excited for what lies ahead. I have been on the quest of taking my health, my consciousness and my life up the notch so I can never be short of the energy needed to keep serving others. It is a never-ending process (an infinite game) and that’s the beauty of it.</p>

            </Sections>

            <Sections classes="qoute purquopte me">
                <div className="quotes">Someone wise once said, before you try to fix someone else's vibe, make sure you are not killing yours in the process. Protect your own energy first. You are not obliged to heal someone at the cost of your own well-being. Fill your cup first, so others can drink from its overflow.</div>
            </Sections>


            <Sections classes="conLight collaboration conGreenish">
                <div className="flex aic">
                    <div className="imgHeightControl">
                        <img src={process.env.PUBLIC_URL + "./assets/img/me/myPick13.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl">
                        <p>I am grateful to be handed over this uniquely gifted human life form and for being on a self-transformational experience from: </p>
                        <ul className="list italic">
                            <li>Fat to Fit</li>
                            <li>Bullied to Respected</li>
                            <li>Directionless to Purposeful</li>
                            <li>Depressed to Driven</li>
                            <li>Confused to Conscious</li>
                            <li>Emotionally Bankrupt to Replenished & Empowered</li>
                            <li>Heartbreak to Healed Heartset</li>
                            <li>Unemployed to Business Owner</li>
                            <li>Self-Doubt to Self-Esteem</li>
                            <li>Having Toxic Associations to Uplifting Ones</li>
                            <li>Insecure to Internally Liberated</li>
                            <li>Anxious to Peaceful</li>
                            <li>Blaming to Taking Ownership</li>
                            <li>Complaining to Grateful</li>
                            <li>Angry to Forgiving</li>
                            <li>Guilt to Self-Compassion</li>
                            <li>Procrastinating to Acting</li>
                            <li>Criticising to Accepting or Letting Go</li>
                            <li>Problem-Focused to Solution-Focused</li>
                            <li>Victim to Creator</li>
                        </ul>
                      

                    </div>
                </div>
                <p><b>Everything I’ve been through has turned out to be a blessing and some in disguise. I was being prepared for something greater than myself. I was being prepared for this.</b></p>

<p><b>Looking back, I can now connect the dots. Let me help you connect yours and take you on your very own authentic transformational journey.</b></p>

<p><b>So if you are committed to being your true self and invest in your growth & success, we should have a conversation.</b></p>
<div className="text-center">
    <a href="https://calendly.com/coachingwithshubham/complimentary-discovery-coaching-call" className="link">BOOK YOUR COMPLIMENTARY DISCOVERY CALL</a>
  <p className="text-center"> <b>OR</b></p>
   <a href="/1:1-Coaching-Program" className="link">VIEW MY COACHING PROGRAM
</a>
</div>
            </Sections>

        </>
    )
}

export default Me
