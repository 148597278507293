// import { Link } from "react-router-dom"

import Sections from "../Sections"
const Coach = () => {
    return (
        <Sections classes="conDark">
        <div className="row aic coach">
        <h2>HOW CAN I HELP YOU?</h2>
            <div className="col-7 col-md-6 col-sm-12 ">

            <a className="link" href="/life-coaching"><i className="i-flower"/> LIFE COACHING FOR INDIVIDUALS </a>
            <a className="link" href="/career-coaching"><i className="i-job"/> CAREER COACHING FOR INDIVIDUALS </a>
        <a className="link" href="/group-coaching-for-organizations"><i className="i-e-gear"/> GROUP (TEAM) COACHING FOR ORGANIZATIONS</a>
            </div>
            <div className="col-5 col-md-6 col-sm-12  text-right">
                <img src={process.env.PUBLIC_URL + "./assets/img/home-page/coach.jpg"} alt="shubham agarwal" />
            </div>
        </div>

    </Sections>
    )
}

export default Coach
