
const BannerTwo = (props) => {
    return (
        <div className="banner BannerTwo">
            <img src={props.bg} alt="banner" className="bannerimg2" />
            <div className="bannercon">
                <div className="container text-left">
                    <div className="row cc">
                        <div className="col-12 col-md-12 col-sm-12">
                            <h1>{props.hOne}</h1>
                            <h2>{props.hTwo}</h2>
                            <div><a className="link" href={props.link}>{props.linkc}</a></div>
                            <h3>{props.hThree}</h3>
                            <p>{props.content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// assets/img/home-page/2-2.jpg
export default BannerTwo
